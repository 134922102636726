import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_helpers/auth.guard';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin/admin.component';
import { AgGridModule } from 'ag-grid-angular';
import { ActionCellRendererComponent } from './admin/action-cell-renderer/action-cell-renderer.component';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { CheckboxRendererComponent } from './admin/checkbox-cell-renderer/checkbox-cell-renderer.component';
import { ParentComponent } from './parent/parent.component';
import { MultimediaCellRendererComponent } from './admin/multimedia-cell-renderer/multimedia-cell-renderer.component';
import { ImageCellRendererComponent } from './admin/image-cell-renderer/image-cell-renderer.component';
import { UserEditorComponent } from './admin/users/user-editor/user-editor.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        AdminComponent,
        ParentComponent,
        ActionCellRendererComponent,
        CheckboxRendererComponent,
        MultimediaCellRendererComponent,
        ImageCellRendererComponent,
        UserEditorComponent,
    ],
    imports: [
        BrowserModule,
        CommonModule,
        HttpClientModule,
        FormsModule,
        AgGridModule.withComponents([
            CheckboxRendererComponent,
            MultimediaCellRendererComponent,
            ImageCellRendererComponent,
        ]),
        RouterModule.forRoot(
            [
                {
                    path: '',
                    component: HomeComponent,
                    canActivate: [AuthGuard],
                },
                { path: 'login', component: LoginComponent },
                { path: '**', redirectTo: '' },
            ],
            { relativeLinkResolution: 'legacy' }
        ),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
