import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RowDragEndEvent } from 'ag-grid-community';
import { Attachment } from '../_models/attachment';
import { Cycle } from '../_models/cycle';
import { KeyValue } from '../_models/key-value';
import { Memorization } from '../_models/memorization';
import { ResponseData } from '../_models/response-data';
import { User } from '../_models/user';
import { AttachmentService } from '../_services/attachment.service';
import { UiService } from '../_services/ui.service';
import { ActionCellRendererComponent } from './action-cell-renderer/action-cell-renderer.component';
import { AdminService } from './admin-service';
import { CheckboxRendererComponent } from './checkbox-cell-renderer/checkbox-cell-renderer.component';
import { ImageCellRendererComponent } from './image-cell-renderer/image-cell-renderer.component';
import { MultimediaCellRendererComponent } from './multimedia-cell-renderer/multimedia-cell-renderer.component';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
    public users = new Array<User>();
    public selectedUser = null;

    private memorizationFromServer: Array<Memorization>;
    private gridApi;
    private rowData = new Array<Memorization>();
    private columnDefs = [
        {
            field: 'actions',
            headerName: 'Actions',
            editable: false,
            suppressNavigable: true,
            cellClass: 'no-border',
            resizable: false,
            width: 110,
            cellRendererFramework: ActionCellRendererComponent,
            cellStyle: {
                textAlign: 'center',
            },
        },
        {
            field: 'grade',
            headerName: 'Grade',
            width: 125,
        },
        {
            field: 'quarter',
            headerName: 'Quarter',
            width: 100,
            cellStyle: {
                textAlign: 'center',
            },
            resizable: false,
        },
        {
            field: 'week',
            headerName: 'Week',
            width: 75,
            cellStyle: {
                textAlign: 'center',
            },
            resizable: false,
        },
        {
            field: 'question',
            headerName: 'Question',
            flex: 1,
            cellStyle: { 'word-break': 'break-word' },
            wrapText: true,
            autoHeight: true,
        },
        {
            field: 'answer',
            headerName: 'Answer',
            flex: 1,
            cellStyle: { 'word-break': 'break-word' },
            wrapText: true,
            autoHeight: true,
        },
        {
            field: 'multimedia',
            headerName: 'Multimedia',
            width: 150,
            cellRendererFramework: MultimediaCellRendererComponent,
        },
        {
            field: 'image',
            headerName: 'Image',
            width: 150,
            cellRendererFramework: ImageCellRendererComponent,
        },
        { field: 'link', headerName: 'Link', width: 150 },
    ];
    public gridOptions = {
        // PROPERTIES
        // rowDragManaged: true,
        defaultColDef: {
            resizable: true,
            editable: true,
            sortable: false,
        },
        columnDefs: this.columnDefs,
        pagination: false,
        rowSelection: 'single',
        stopEditingWhenGridLosesFocus: true,

        onCellValueChanged: (event) => {
            if (event.oldValue === null && event.newValue === undefined) {
                // ignore the change. Nothing was actually changed
            } else {
                this.shouldShowButtons();
            }
        },
        onRowDragEnd: (event: RowDragEndEvent) => {
            this.gridApi.forEachNode(
                (node) => ((node.data as Memorization).sequence = node.rowIndex)
            );
            this.rowData.sort(this.sortRowData);
            this.shouldShowButtons();
        },
    };
    public selectedArea = 'Memorization';
    public subjects = new Array<{
        keyValue: KeyValue<string, string>;
        selected: boolean;
    }>();
    public displayedCycle: Cycle;
    public displayedCycles = new Array<Cycle>();
    public currentCycle: Cycle;
    public currentCycles = new Array<Cycle>();

    public showButtons = false;
    public frameworkComponents: any;
    constructor(
        private http: HttpClient,
        private adminSvc: AdminService,
        private attachmentSvc: AttachmentService,
        private uiService: UiService
    ) {
        this.frameworkComponents = {
            checkboxRenderer: CheckboxRendererComponent,
        };
    }

    private shouldShowButtons() {
        this.showButtons =
            JSON.stringify(this.rowData) !==
            JSON.stringify(
                this.memorizationFromServer
                    .filter(
                        (x) =>
                            x.subject ===
                            this.subjects.find((q) => q.selected === true)
                                .keyValue.key
                    )
                    .sort(this.sortRowData)
            );
        this.adminSvc.dirtyChanged(this.showButtons);
    }

    private generateUUID() {
        // Public Domain/MIT
        let d = new Date().getTime(); // Timestamp
        let d2 =
            (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                let r = Math.random() * 16; // random number between 0 and 16
                if (d > 0) {
                    // Use timestamp until depleted
                    // tslint:disable-next-line: no-bitwise
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else {
                    // Use microseconds since page-load if supported
                    // tslint:disable-next-line: no-bitwise
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                // tslint:disable-next-line: no-bitwise
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
    }

    private refreshGridData(): void {
        if (this.gridApi === null || this.gridApi === undefined) {
            return;
        }
        // this.rowData.sort(this.sortRowData);
        this.rowData = JSON.parse(
            JSON.stringify(
                this.memorizationFromServer
                    .filter(
                        (x) =>
                            x.subject ===
                            this.subjects.find((q) => q.selected === true)
                                .keyValue.key
                    )
                    .sort(this.sortRowData)
            )
        );
        this.shouldShowButtons();
        this.gridApi.setRowData(this.rowData);
    }
    public currentWeekOnlySelectorTop = 0;
    public currentWeekOnlySelectorLeft = 0;
    public currentWeekOnlySelectorDisplay = 'none';
    public selectedMemorization: Memorization;
    public ngOnInit() {
        this.adminSvc.onAddNewRow.subscribe((index) => {
            this.gridApi.applyTransaction({
                add: [this.getNewRowData()],
                addIndex: index + 1,
            });

            this.rowData = new Array<Memorization>();
            this.gridApi.forEachNode((node) => {
                // (node.data as Memorization).sequence = node.rowIndex;
                this.rowData.push(node.data);
            });
            this.resequenceRows(this.rowData);
            this.shouldShowButtons();
        });

        this.adminSvc.onDeleteRow.subscribe((index) => {
            this.gridApi.applyTransaction({
                remove: [this.rowData[index]],
            });

            this.rowData = new Array<Memorization>();
            this.gridApi.forEachNode((node) => {
                // (node.data as Memorization).sequence = node.rowIndex;
                this.rowData.push(node.data);
            });
            this.resequenceRows(this.rowData);
            this.shouldShowButtons();
        });

        this.adminSvc.onCurrentWeekOnlyChanged.subscribe(() =>
            this.shouldShowButtons()
        );

        this.adminSvc.onCurrentWeekOnlyChangeRequested.subscribe((data) => {
            this.currentWeekOnlySelectorTop = data.top;
            this.currentWeekOnlySelectorLeft = data.left;
            this.currentWeekOnlySelectorDisplay = 'flex';
            this.selectedMemorization = data.data;
            this.shouldShowButtons();
        });

        this.adminSvc.onAttachmentAdded.subscribe(() =>
            this.shouldShowButtons()
        );

        this.adminSvc.onUserSaved.subscribe((u: User) => {
            if (u?.unique_Id !== null && u?.unique_Id !== undefined) {
                const idx = this.users.findIndex(
                    (x) => x.unique_Id === u.unique_Id
                );
                if (idx > -1) {
                    this.users.splice(idx, 1, u);
                    this.uiService.showAlert(
                        `${u.firstName} ${u.lastName} has been updated.`
                    );
                }
            } else {
                this.getUsersFromServer().then(() => {
                    this.uiService.showAlert(
                        `${u.firstName} ${u.lastName} has been added.`
                    );
                });
            }
        });

        this.adminSvc.onUserDeleted.subscribe((u: User) => {
            const idx = this.users.findIndex(
                (x) => x.unique_Id === u.unique_Id
            );
            if (idx > -1) {
                this.users.splice(idx, 1);
                this.uiService.showAlert(
                    `${u.firstName} ${u.lastName} has been deleted.`
                );
            }
        });

        this.getCyclesFromServer().then(() => {
            this.getSubjectsFromServer().then(() => {
                setTimeout(() => {
                    this.getMemorizationFromServer();
                }, 250);
            });
        });

        this.getUsersFromServer();
    }

    public setCurrentWeekOnlyIdentifier(color: string): void {
        this.selectedMemorization.currentWeekOnly = true;
        this.selectedMemorization.currentWeekOnlyIdentifier = color;
        this.closeCurrentWeekOnlySelector();
        this.shouldShowButtons();
    }

    public removeCurrentWeekOnlyIndicator(): void {
        this.selectedMemorization.currentWeekOnly = false;
        this.selectedMemorization.currentWeekOnlyIdentifier = null;
        this.closeCurrentWeekOnlySelector();
        this.shouldShowButtons();
    }

    public closeCurrentWeekOnlySelector(): void {
        this.currentWeekOnlySelectorTop = 0;
        this.currentWeekOnlySelectorLeft = 0;
        this.currentWeekOnlySelectorDisplay = 'none';
    }

    public onGridReady(params) {
        this.gridApi = params.api;
        this.gridApi.setRowData(this.rowData);
    }

    public changeArea(area: string): void {
        if (this.attachmentSvc.currentlyUploadingFile() === true) {
            this.uiService.showAlert(
                'An attachment is currently being uploaded. Please wait until this process has completed before changing areas.'
            );
            return;
        } else {
            if (this.showButtons === true) {
                this.adminSvc.forceSaveMessage();
                return;
            }
            this.selectedArea = area;
        }
    }

    public changeSubject(subject: { name: string; selected: boolean }): void {
        if (this.attachmentSvc.currentlyUploadingFile() === true) {
            this.uiService.showAlert(
                'An attachment is currently being uploaded. Please wait until this process has completed before changing subjects.'
            );
            return;
        } else {
            if (this.showButtons === true) {
                this.adminSvc.forceSaveMessage();
                return;
            }
            this.subjects.forEach((s) => (s.selected = false));
            subject.selected = true;

            this.refreshGridData();
        }
    }

    public saveChanges(): void {
        if (this.attachmentSvc.currentlyUploadingFile() === true) {
            this.uiService.showAlert(
                'An attachment is currently being uploaded. Please wait until this process has completed before saving your changes.'
            );
            return;
        } else {
            const lastRow = this.rowData[this.rowData.length - 1];
            if (this.isNewRow(lastRow)) {
                this.rowData.splice(this.rowData.length - 1, 1);
            }
            const selectedSubject = this.subjects.find(
                (s) => s.selected === true
            );
            this.http
                .post<ResponseData<Array<KeyValue<string, string>>>>(
                    `/api/memorization/savememorization/${this.displayedCycle.unique_Id}/${selectedSubject.keyValue.key}`,
                    this.rowData
                )
                .subscribe((response) => {
                    this.getMemorizationFromServer();
                });
        }
    }

    public discardChanges(): void {
        if (this.attachmentSvc.currentlyUploadingFile() === true) {
            this.uiService.showAlert(
                'An attachment is currently being uploaded. Please wait until this process has completed before discarding your changes.'
            );
            return;
        } else {
            this.refreshGridData();
            this.shouldShowButtons();
        }
    }

    public displayCycleChanged(): void {
        if (this.showButtons === true) {
            this.adminSvc.forceSaveMessage();
            return;
        }
        this.getMemorizationFromServer();
    }

    public setCycle(): void {
        this.http
            .post<ResponseData<any>>(
                '/api/cycle/setcurrentcycle',
                this.currentCycle
            )
            .subscribe();
    }

    private getCyclesFromServer(): Promise<void> {
        return new Promise((good) => {
            this.http
                .post<ResponseData<Array<Cycle>>>('/api/cycle', null)
                .subscribe((response) => {
                    if (response.success === true) {
                        response.responseData.forEach((cycle: Cycle) => {
                            this.displayedCycles.push(Cycle.create(cycle));
                            this.currentCycles.push(Cycle.create(cycle));

                            if (cycle.isCurrent === true) {
                                this.displayedCycle =
                                    this.displayedCycles[
                                        this.displayedCycles.length - 1
                                    ];
                                this.currentCycle =
                                    this.currentCycles[
                                        this.currentCycles.length - 1
                                    ];
                            }
                        });
                        good();
                    }
                });
        });
    }

    private getSubjectsFromServer(): Promise<boolean> {
        return new Promise((good) => {
            this.http
                .post<ResponseData<Array<KeyValue<string, string>>>>(
                    '/api/Subject',
                    null
                )
                .subscribe((response) => {
                    if (response.success === true) {
                        response.responseData.forEach(
                            (kv: KeyValue<string, string>) => {
                                this.subjects.push({
                                    keyValue: kv,
                                    selected: false,
                                });
                            }
                        );
                        this.subjects[0].selected = true;
                        good(true);
                    }
                });
        });
    }

    private getMemorizationFromServer(): Promise<boolean> {
        return new Promise((good) => {
            this.http
                .post<ResponseData<Array<Memorization>>>(
                    'api/memorization',
                    this.displayedCycle
                )
                .subscribe((r) => {
                    r.responseData.forEach((x) => {
                        if (x.image !== null && x.image !== undefined) {
                            x.image = Attachment.create(x.image);
                        }
                    });
                    // add a blank row for each subject
                    this.subjects.forEach((kv) => {
                        const row = r.responseData.push(this.getNewRowData(kv));

                        this.resequenceRows(
                            r.responseData.filter(
                                (f) => f.subject === kv.keyValue.key
                            )
                        );
                    });

                    this.memorizationFromServer = r.responseData;
                    this.refreshGridData();
                    good(true);
                });
        });
    }

    private resequenceRows(data: Array<Memorization>) {
        for (let index = 0; index < data.length; index++) {
            data[index].sequence = index;
        }
        data.sort(this.sortRowData);
    }

    private getNewRowData(
        subject: {
            keyValue: KeyValue<string, string>;
            selected: boolean;
        } = null
    ): Memorization {
        let s: {
            keyValue: KeyValue<string, string>;
            selected: boolean;
        };
        if (subject === null || subject === undefined) {
            s = this.subjects.find((q) => q.selected === true);
        } else {
            s = subject;
        }
        const m = {
            unique_id: this.generateUUID(),
            subject: s.keyValue.key,
            grade: null,
            week: null,
            question: null,
            answer: null,
            multimedia: null,
            image: null,
            sequence: 9999,
        } as Memorization;

        return m;
    }

    private isNewRow(m: Memorization): boolean {
        const inow = this.isNullOrWhitespace;
        return (
            inow(m.answer) &&
            (inow(m.currentWeekOnly) || m.currentWeekOnly === false) &&
            inow(m.grade) &&
            inow(m.image) &&
            inow(m.link) &&
            inow(m.multimedia) &&
            inow(m.quarter) &&
            inow(m.question) &&
            inow(m.week)
        );
    }

    private sortRowData(a: Memorization, b: Memorization) {
        // const compareGrade = a.grade?.localeCompare(b.grade);
        // const compareWeek = a.week - b.week;
        const compareSequence = a.sequence - b.sequence;
        // return compareGrade || compareWeek || compareSequence;
        return compareSequence;
    }

    private isNullOrWhitespace(value: any): boolean {
        let r = false;
        if (value === null || value === undefined) {
            r = true;
        } else if (value.toString().trim().lenght === 0) {
            r = true;
        }

        return r;
    }

    private getUsersFromServer(): Promise<void> {
        this.users = new Array<User>();
        return new Promise<void>((good) => {
            this.http
                .post<ResponseData<Array<User>>>('/api/user', null)
                .subscribe((response) => {
                    response.responseData.forEach((u) => this.users.push(u));
                    good();
                });
        });
    }

    public addUser(): void {
        this.adminSvc.editUser(new User());
    }
    public selectUser(u: User): void {
        this.selectedUser = u;
    }

    public editUser(u: User): void {
        this.selectedUser = u;
        this.adminSvc.editUser(u);
    }

    public deleteUser(u: User): void {
        this.selectedUser = u;
        this.http
            .post<ResponseData<any>>('api/user/delete/', this.selectedUser)
            .subscribe((r) => {
                this.adminSvc.userDeleted(this.selectedUser);
                this.adminSvc.closeEditUser();
            });
    }

    public import(): void {
        this.http.post<ResponseData<any>>('api/user/import/', null).subscribe();
    }
}
