import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ResponseData } from '../_models/response-data';
import { User } from '../_models/user';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(private http: HttpClient) {}

    private _validatingToken = false;
    public get validatingToken(): boolean {
        return this._validatingToken;
    }

    private _isLoggedIn = false;
    public get isLoggedIn(): boolean {
        return this._isLoggedIn;
    }

    private _isLoggedOut = false;
    public get isLoggedOut(): boolean {
        return this._isLoggedOut;
    }

    private _user: User;
    public get User(): User {
        return this._user;
    }

    public get token(): string {
        let t = localStorage.getItem('tokenInfo');
        return t;
    }

    public authenticate(token): Promise<void> {
        return new Promise((good, bad) => {
            localStorage.setItem('tokenInfo', token);
            this._validatingToken = true;
            this.http
                .post<ResponseData<any>>('/api/auth/authenticate', null)
                .subscribe((r) => {
                    if (r.success === true) {
                        localStorage.setItem('tokenInfo', r.responseData.token);
                        this._user = r.responseData.user as User;
                        this._isLoggedIn = true;
                        good();
                    } else {
                        bad(r.errorMessage);
                    }
                });
        });
    }

    public validateToken(): Promise<boolean> {
        return new Promise((good) => {
            if (this.token === null || this.token === undefined) {
                good(false);
            } else {
                this._validatingToken = true;
                this.http
                    .post<ResponseData<{ user: User; token: string }>>(
                        '/api/auth/ValidateToken',
                        null
                    )
                    .pipe(
                        map((response) => {
                            return response;
                        })
                    )
                    .subscribe((response) => {
                        this._validatingToken = false;
                        if (response.success === true) {
                            let t = localStorage.getItem('tokenInfo');
                            if (t !== null && t !== undefined) {
                                localStorage.setItem(
                                    'tokenInfo',
                                    response.responseData.token
                                );
                                this._user = response.responseData.user as User;
                                this._isLoggedIn = true;
                                good(true);
                            }
                        } else {
                            good(false);
                        }
                    });
            }
        });
    }

    logout() {
        this._isLoggedIn = false;
        this._isLoggedOut = true;
        localStorage.removeItem('tokenInfo');
        sessionStorage.removeItem('tokenInfo');
    }
}
