import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ResponseData } from 'src/app/_models/response-data';
import { User } from 'src/app/_models/user';
import { AdminService } from '../../admin-service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'user-editor',
    templateUrl: './user-editor.component.html',
    styleUrls: ['./user-editor.component.css'],
})
export class UserEditorComponent implements OnInit {
    public selectedUser: User = null;

    constructor(private http: HttpClient, private adminSvc: AdminService) {}

    ngOnInit() {
        this.adminSvc.onEditUserRequested.subscribe((u: User) => {
            this.selectedUser = Object.assign(new User(), u);
        });
    }

    public cancel(): void {
        this.adminSvc.closeEditUser();
    }

    public save(): void {
        this.http
            .post<ResponseData<any>>('api/user/save/', this.selectedUser)
            .subscribe((r) => {
                this.adminSvc.userSaved(this.selectedUser);
                this.adminSvc.closeEditUser();
            });
    }
}
