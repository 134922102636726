<div class="parent-container">
    <div class="memory-selections">
        <div style="display: flex; margin-right: 21px;">
            <div style=" padding-right: 9px;">Grade:</div>
            <div>
                <select style="width:50px;" [(ngModel)]="selectedGrade" (change)="selectionChanged() ">
                    <option *ngFor="let grade of grades" [ngValue]="grade ">{{ grade }}</option>
                </select>
            </div>
        </div>

        <div style="display: flex; margin-right: 21px;">
            <div style="padding-right: 9px;">Quarter:</div>
            <div>
                <select style="width:50px;" [(ngModel)]="selectedQuarter" (change)="selectionChanged() ">
                    <option *ngFor="let q of quarters" [ngValue]="q">{{ q }}</option>
                </select>
            </div>
        </div>

        <div style="display: flex; margin-right: 21px;">
            <div style="padding-right: 9px;">Week:</div>
            <div>
                <select style="width:50px;" [(ngModel)]="selectedWeek" (change)="selectionChanged() ">
                  <option *ngFor="let w of weeks" [ngValue]="w">{{ w }}</option>
              </select>
            </div>
        </div>

        <div style="display: flex; margin-right: 21px;">
            <div>
                <select [(ngModel)]="workType" (change)="selectionChanged()">
                    <option value="Current">Current</option>
                    <option value="Cumulative">Cumulative</option>
                </select>
            </div>
        </div>
    </div>

    <div *ngIf="showSelectMsg || showNothingFoundMsg" style="margin-top: 100px; width: 100%; text-align: center;color: white; font-size: 18px; font-weight: bold; letter-spacing: 1px;">
        <span *ngIf="showSelectMsg">Please select a grade, quarter, and week to display the memory work.</span>
        <span *ngIf="showNothingFoundMsg">There is no memory work to display.</span>
    </div>

    <div *ngIf="showSelectMsg !== true && showNothingFoundMsg !== true" style="margin: 18px;background-color: white;padding: 18px;border-radius: 24px;color: #183153;">
        <div *ngFor="let subject of displayedMemorization" style="padding-bottom: 18px;">
            <div style="font-size: 32px; font-weight: bolder;  border-bottom: 1px solid;">
                {{subject.subjectText}}
            </div>

            <div style="margin-bottom: 12px; padding-left:12px; padding-top:12px;" *ngFor="let question of subject.questions">
                <div style="font-weight: bolder;font-size:18px;">
                    <div *ngIf="question.link && question.link.trim().length !== 0">
                        <a href="{{question.link}}" target="_blank">{{question.questionText}}</a>
                    </div>
                    <div *ngIf="!question.link || question.link?.trim().length === 0">
                        {{question.questionText}}
                    </div>
                </div>
                <div style="padding-left: 18px;" *ngFor="let answer of question.answers">
                    <div>
                        {{answer}}
                    </div>
                </div>
                <div *ngIf="question.multimedias.length > 0" style="padding-left: 18px;">
                    <div style="font-weight: bold; margin-top: 12px;">
                        Multimedia
                    </div>
                    <ng-container *ngFor="let multimedia of question.multimedias;">
                        <div *ngIf="multimedia !== null && multimedia !== undefined">
                            <span (click)="preview(multimedia.filePath)" style="cursor: pointer; color: #0366d6; text-decoration: underline;">{{multimedia.name}}</span>
                        </div>
                    </ng-container>
                </div>
                <div *ngIf="question.images.length > 0" style="padding-left: 18px;">
                    <div style="font-weight: bold; margin-top: 12px;">
                        Images
                    </div>
                    <ng-container *ngFor="let image of question.images;">
                        <div *ngIf="image !== null && image !== undefined && image.filePath !== null && image.filePath !== undefined">
                            <img [src]="getImagePath(image.filePath)" style="max-width: 200px; cursor: pointer; margin-top:12px;" (click)="previewImage(image.filePath)" />
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>