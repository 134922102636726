import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin-service';

@Component({
    templateUrl: './action-cell-renderer.component.html',
    styleUrls: ['./action-cell-renderer.component.css'],
})
export class ActionCellRendererComponent implements OnInit {
    public params: any;

    constructor(private adminSvc: AdminService) {}

    ngOnInit() {}

    agInit(params) {
        this.params = params;
    }

    public add(): void {
        this.adminSvc.addNewRow(this.params.node.data.sequence);
    }

    public delete(): void {
        this.adminSvc.deleteRow(this.params.node.data.sequence);
    }

    public onlyForThisWeek(event: MouseEvent) {
        this.adminSvc.currentWeekOnlyChangeRequested(
            event.y,
            event.x,
            this.params.node.data
        );
    }
}
