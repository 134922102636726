<div class="home-container">
    <div class="title-container">
        <div class="title"></div>
        <div class="views">
            <div *ngIf="userRole === 'Administrator' || userRole === 'System Administrator'" class="view" [ngClass]="{'selected': selectedView === 'parent'}" (click)="changeView('parent')">Parent View</div>
            <div *ngIf="userRole === 'Administrator' || userRole === 'System Administrator'" class="view" [ngClass]="{'selected': selectedView === 'admin'}" (click)="changeView('admin')">Administrator View</div>
            <div class="view" (click)="logOut()">Sign Out</div>
        </div>
    </div>
    <div style="padding-top:60px;">
        <ng-container *ngIf="selectedView === 'admin'">
            <admin></admin>
        </ng-container>
        <ng-container *ngIf="selectedView === 'parent'">
            <parent></parent>
        </ng-container>
    </div>
    <div *ngIf="shouldSaveChanges===true" style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgba(0,0,0,.7); color: white">
        <div style="font-family: 'Raleway', sans-serif;font-size: 24px; font-weight: 600;">Please Save or Discard your changes before continuing.</div>
        <div class="button" style="margin-top:18px; width: 75px;" (click)="dismiss()">Ok</div>
    </div>

    <div *ngIf="shouldShowMultimediaPreview===true" style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgba(0,0,0,.7); color: white">
        <video width="400" controls>
        <source [src]="previewingFileName">
        Your browser does not support HTML video.
      </video>
        <div (click)="closePreview()" style="margin-top: 18px;" class="button">Close</div>
    </div>

    <div *ngIf="shouldShowImagePreview===true" style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgba(0,0,0,.7); color: white">
        <img [src]="previewingFileName" style="max-width: 400px;" />
        <div (click)="closePreview()" style="margin-top: 18px;" class="button">Close</div>
    </div>

    <div *ngIf="shouldShowAlert===true" style="position: fixed; top: 0px; left: 0px; right: 0px; bottom: 0px; display: flex; flex-direction: column; justify-content: center; align-items: center; background-color: rgba(0,0,0,.7); color: white">
        <div style="max-width:600px; font-family: 'Montserrat', sans-serif; font-size:24px; text-align: center;">
            {{alertText}}
        </div>
        <div (click)="shouldShowAlert = false" style="margin-top: 18px;" class="button">Close</div>
    </div>
    <ng-container>
        <user-editor *ngIf="shouldShowUserEditor === true"></user-editor>
    </ng-container>


</div>