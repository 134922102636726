<div class="user-editor">
    <div class="container">
        <div style="height: 12px;"></div>
        <div class="title">
            <span *ngIf="selectedUser?.unique_Id === null || selectedUser?.unique_Id === undefined">New User</span>
            <span *ngIf="selectedUser?.unique_Id !== null && selectedUser?.unique_Id !== undefined">Editing User</span>
        </div>
        <div class="fields">
            <div class="field">
                <div class="label"> First Name: </div>
                <div> <input type="text" [(ngModel)]="selectedUser.firstName"> </div>
            </div>
            <div class="field">
                <div class="label"> Last Name: </div>
                <div> <input type="text" [(ngModel)]="selectedUser.lastName"> </div>
            </div>
            <div class="field">
                <div class="label"> Email: </div>
                <div> <input type="text" [(ngModel)]="selectedUser.email"> </div>
            </div>
            <div class="field">
                <div class="label"> Role: </div>
                <div>
                    <select [(ngModel)]="selectedUser.role">
                      <option value=""></option>
                      <option value="System Administrator">System Administrator</option>
                      <option value="Administrator">Administrator</option>
                      <option value="User">User</option>
                </select>
                </div>
            </div>
        </div>
        <div class="buttons">
            <div class="button save" (click)="save()">Save</div>
            <div class="button discard" (click)="cancel()">Cancel</div>
        </div>
        <div style="height: 12px;"></div>
    </div>
</div>