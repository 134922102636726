<div style="display: flex;">
    <div *ngIf="(fileName === null || fileName === undefined) && isUploading !== true">
        <input [attr.id]="'imageUpload' + params.node.data.sequence" type="file" accept="image/*" style="display: none" (change)="imageSelected($event)" />
        <label style="float: right; text-decoration: underline; cursor: pointer;" [attr.for]="'imageUpload' + params.node.data.sequence">Upload</label>
    </div>
    <div style="display: flex;" *ngIf="(fileName !== null && fileName !== undefined) && isUploading !== true">
        <div style="margin-right: 6px;" title="Delete this attachment">
            <svg style="width: 12px; cursor: pointer;" (click)="delete()" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" class="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>
        </div>
        <div (click)="preview()">
            {{fileName}}
        </div>
    </div>
    <div *ngIf="isUploading === true">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" class="rotate" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg>{{percentDone}}
        {{percentUploaded}}% complete
    </div>
</div>