import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { Attachment } from '../_models/attachment';
import { ResponseData } from '../_models/response-data';
import { AuthenticationService } from './authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AttachmentService {
    private readonly sliceSize = 2000 * 1024;
    private file: File;
    private fileName: string;
    private reader: FileReader;
    private uniqueFileName: string;
    private percentCompleted = 0;
    private isUploading = false;

    private _previewMultimediaSource = new Subject<SafeUrl>();
    private _previewImageSource = new Subject<SafeUrl>();
    private _uploadAttachmentProgressSource = new Subject<number>();

    public onPreviewMultimedia = this._previewMultimediaSource.asObservable();
    public onPreviewImage = this._previewImageSource.asObservable();
    public onUploadAttachmentProgress =
        this._uploadAttachmentProgressSource.asObservable();

    constructor(
        private http: HttpClient,
        private authSvc: AuthenticationService
    ) {}

    public currentlyUploadingFile(): boolean {
        return this.isUploading === true;
    }

    public uploadAttachmentProgress(percentage: number): void {
        this._uploadAttachmentProgressSource.next(percentage);
    }

    public uploadAttachment(file: File): Promise<string> {
        return new Promise((good, bad) => {
            if (this.isUploading === true) {
                bad(
                    'Another file is currently being uploaded. Please wait until it has completed before trying to upload another file.'
                );
            } else {
                this.isUploading = true;
                this.file = file;
                this.uniqueFileName =
                    this.generateUUID() +
                    '.' +
                    this.file.name.split('.')[
                        this.file.name.split('.').length - 1
                    ];
                this.reader = new FileReader();
                this.percentCompleted = 0;
                this.fileName = file.name;

                this.startUpload(0);

                const tick = setInterval(() => {
                    if (this.percentCompleted >= 100) {
                        clearInterval(tick);
                        this.isUploading = false;
                        good(this.uniqueFileName);
                    }
                }, 500);
            }
        });
    }

    private startUpload(startPosition: number): void {
        const nextSlice = startPosition + this.sliceSize + 1;
        const blob = this.file.slice(startPosition, nextSlice);
        this.reader.onload = function (ev) {
            if (ev.target.readyState !== FileReader.DONE) {
                return;
            }
            const self = this as AttachmentService;
            const s = ev.target.result.toString();
            const base64 = s.substr(s.indexOf(',') + 1);
            const fd = new FormData();
            fd.append('attachmentBytes', base64);
            fd.append('name', self.file.name);
            fd.append('filePath', self.uniqueFileName);
            self.http
                .post<ResponseData<Attachment>>(
                    '/api/memorization/saveattachment/',
                    fd
                )
                .subscribe((response) => {
                    setTimeout(() => {
                        const size_done = startPosition + self.sliceSize;
                        self.percentCompleted = Math.floor(
                            (size_done / self.file.size) * 100
                        );

                        if (self.percentCompleted > 100) {
                            self.percentCompleted = 100;
                        }
                        if (nextSlice < self.file.size) {
                            self.uploadAttachmentProgress(
                                self.percentCompleted
                            );
                            self.startUpload(nextSlice);
                        }
                    });
                });
        }.bind(this);

        this.reader.readAsDataURL(blob);
    }

    public previewMultimedia(fileName: string): void {
        this._previewMultimediaSource.next(
            'api/memorization/getmultimedia/' +
                fileName +
                '?t=' +
                encodeURIComponent(this.authSvc.token)
        );
    }

    public previewImage(fileName: string): void {
        this._previewImageSource.next(
            'api/memorization/getImage/' +
                fileName +
                '?t=' +
                encodeURIComponent(this.authSvc.token)
        );
    }

    private generateUUID() {
        // Public Domain/MIT
        let d = new Date().getTime(); // Timestamp
        let d2 =
            (performance && performance.now && performance.now() * 1000) || 0; // Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
            /[xy]/g,
            function (c) {
                let r = Math.random() * 16; // random number between 0 and 16
                if (d > 0) {
                    // Use timestamp until depleted
                    // tslint:disable-next-line: no-bitwise
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else {
                    // Use microseconds since page-load if supported
                    // tslint:disable-next-line: no-bitwise
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                // tslint:disable-next-line: no-bitwise
                return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
            }
        );
    }
}
