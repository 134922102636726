import { KeyValue } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Memorization } from '../_models/memorization';
import { MemorizationParent } from '../_models/memorization-parent';
import { ResponseData } from '../_models/response-data';
import { AttachmentService } from '../_services/attachment.service';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'parent',
    templateUrl: './parent.component.html',
    styleUrls: ['./parent.component.css'],
})
export class ParentComponent implements OnInit {
    public subjects = new Array<KeyValue<string, string>>();
    public grades = new Array<any>();
    public quarters = new Array<number>();
    public weeks = new Array<number>();
    public selectedGrade: number = null;
    public selectedQuarter: number = null;
    public selectedWeek: number = null;
    public displayedMemorization = new Array<MemorizationParent>();
    public workType = 'Current';
    public get showSelectMsg(): boolean {
        return (
            this.selectedGrade === null ||
            this.selectedQuarter === null ||
            this.selectedWeek === null
        );
    }
    public get showNothingFoundMsg(): boolean {
        return (
            this.selectedGrade !== null &&
            this.selectedQuarter !== null &&
            this.selectedWeek !== null &&
            this.displayedMemorization?.length === 0
        );
    }

    constructor(
        private http: HttpClient,
        private attachmentSvc: AttachmentService,
        private authSvc: AuthenticationService
    ) {}
    ngOnInit(): void {
        this.getgradesquartersweeks();
    }

    private getgradesquartersweeks(): Promise<void> {
        return new Promise((good) => {
            this.http
                .post<
                    ResponseData<{
                        grades: Array<number>;
                        quarters: Array<number>;
                        weeks: Array<number>;
                    }>
                >('api/memorization/getgradesquartersweeks', null)
                .subscribe((r) => {
                    // add a blank row for each subject
                    this.grades.push(null);
                    this.quarters.push(null);
                    this.weeks.push(null);

                    r.responseData.grades.forEach((g) => this.grades.push(g));
                    r.responseData.quarters.forEach((q) =>
                        this.quarters.push(q)
                    );
                    r.responseData.weeks.forEach((w) => this.weeks.push(w));

                    good();
                });
        });
    }

    public selectionChanged(): void {
        if (
            !this.selectedGrade ||
            !this.selectedQuarter ||
            !this.selectedWeek
        ) {
            return;
        }
        if (this.workType === 'Current') {
            this.getCurrentWork();
        } else {
            this.getCumulativeWork();
        }
    }

    public getCurrentWork() {
        this.http
            .post<ResponseData<Array<MemorizationParent>>>(
                'api/memorization/current',
                {
                    grade: this.selectedGrade.toString(),
                    quarter: this.selectedQuarter,
                    week: this.selectedWeek,
                }
            )
            .subscribe((r) => {
                if (r.success === true) {
                    this.displayedMemorization = r.responseData;
                }
            });
    }

    public getCumulativeWork() {
        this.http
            .post<ResponseData<Array<MemorizationParent>>>(
                'api/memorization/cumulative',
                {
                    grade: this.selectedGrade.toString(),
                    quarter: this.selectedQuarter,
                    week: this.selectedWeek,
                }
            )
            .subscribe((r) => {
                if (r.success === true) {
                    this.displayedMemorization = r.responseData;
                }
            });
    }

    public getImagePath(filePath: string): string {
        return `api/memorization/getImage/${filePath}?t=${encodeURIComponent(
            this.authSvc.token
        )}`;
    }

    public preview(filePath: string): void {
        this.attachmentSvc.previewMultimedia(filePath);
    }

    public previewImage(filePath: string): void {
        this.attachmentSvc.previewImage(filePath);
    }
}
