import { Component, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AdminService } from '../admin/admin-service';
import { User } from '../_models/user';
import { AttachmentService } from '../_services/attachment.service';
import { AuthenticationService } from '../_services/authentication.service';
import { UiService } from '../_services/ui.service';
@Component({
    // tslint:disable-next-line: component-selector
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
    private isDirty = false;
    public uploadInProgress = false;
    public selectedView = 'admin';
    public shouldSaveChanges = false;
    public userRole: string = null;
    public shouldShowMultimediaPreview = false;
    public shouldShowImagePreview = false;
    public previewingFileName: SafeUrl;
    public shouldShowAlert = false;
    public alertText = null;
    public shouldShowUserEditor = false;
    public edittingUser = null;

    constructor(
        private adminSvc: AdminService,
        private authSvc: AuthenticationService,
        private attachmentSvc: AttachmentService,
        private uiSvc: UiService,
        private router: Router
    ) {
        this.userRole = this.authSvc.User.role;

        if (this.userRole === 'User') {
            this.selectedView = 'parent';
        } else if (
            this.userRole === 'Administrator' ||
            this.userRole === 'System Administrator'
        ) {
            this.selectedView = 'admin';
        } else {
            this.selectedView = null;
        }
    }

    public ngOnInit() {
        this.adminSvc.onDirtyChanged.subscribe((b) => {
            this.isDirty = b;
        });

        this.adminSvc.onForceSaveMessage.subscribe(() => {
            this.shouldSaveChanges = true;
        });

        this.attachmentSvc.onPreviewMultimedia.subscribe((fileName) => {
            setTimeout(() => {
                this.shouldShowMultimediaPreview = true;
                this.previewingFileName = fileName;
            }, 100);
        });

        this.attachmentSvc.onPreviewImage.subscribe((fileName) => {
            setTimeout(() => {
                this.shouldShowImagePreview = true;
                this.previewingFileName = fileName;
            }, 100);
        });

        this.uiSvc.onShowAlert.subscribe((text) => {
            setTimeout(() => {
                this.shouldShowAlert = true;
                this.alertText = text;
            }, 100);
        });

        this.adminSvc.onEditUserRequested.subscribe((u: User) => {
            if (u !== null && u !== undefined) {
                this.shouldShowUserEditor = true;
            }
        });

        this.adminSvc.onCloseEditUserRequested.subscribe(() => {
            this.shouldShowUserEditor = false;
        });
    }
    public closePreview(): void {
        this.shouldShowMultimediaPreview = false;
        this.shouldShowImagePreview = false;
        this.previewingFileName = null;
    }

    public changeView(view: string): void {
        if (this.isDirty === true) {
            this.shouldSaveChanges = true;
        } else if (this.attachmentSvc.currentlyUploadingFile() === true) {
            this.shouldShowAlert = true;
            this.alertText =
                'An attachment is currently being uploaded. Please wait until this process has completed.';
        } else if (
            view === 'admin' &&
            (this.userRole === 'Administrator' ||
                this.userRole === 'System Administrator')
        ) {
            this.shouldSaveChanges = false;
            this.selectedView = view;
        } else {
            this.shouldSaveChanges = false;
            this.selectedView = view;
        }
    }

    public dismiss(): void {
        this.shouldSaveChanges = false;
    }

    public logOut(): void {
        this.authSvc.logout();
        this.router.navigate(['login']);
    }
}
