<div class="admin-container">
    <div class="area-container">
        <div class="cycle-container">
            <div *ngIf="selectedArea === 'Memorization'">
                <span (click)="import()" style="cursor: help; margin-right:12px;" title="This is the cycle currently being displayed in the admin tool.">Displaying Cycle:</span>
                <select [(ngModel)]="displayedCycle" (change)="displayCycleChanged()">
                  <option *ngFor="let cycle of displayedCycles" [ngValue]="cycle">{{ cycle.cycleText }}</option>
                </select>
            </div>
            <div *ngIf="selectedArea === 'Memorization'" style="margin-left:21px;">
                <span style="cursor: help; margin-right:12px;" title="This is the cycle currently being displayed to the Guardians and Students.">Current Cycle:</span>
                <select [(ngModel)]="currentCycle" (change)="setCycle()">
                  <option *ngFor="let cycle of currentCycles" [ngValue]="cycle">{{ cycle.cycleText }}</option>
                </select>
            </div>
        </div>
        <div [ngClass]="{'selected': selectedArea === 'Users'}" (click)="changeArea('Users')" class="area">Users</div>
        <div [ngClass]="{'selected': selectedArea === 'Memorization'}" (click)="changeArea('Memorization')" class="area">Memorization</div>
        <div *ngIf="showButtons === true" class="button-container">
            <div class="button save" (click)="saveChanges()">Save Changes</div>
            <div class="button discard" (click)="discardChanges()">Discard Changes</div>
        </div>
        <div *ngIf="selectedArea === 'Users'" class="button-container">
            <div class="button save" (click)="addUser()">Add User</div>
        </div>
    </div>
    <div *ngIf="selectedArea === 'Memorization'">
        <ag-grid-angular style="width: 100%; height: 500px;" class="ag-theme-alpine" [frameworkComponents]="frameworkComponents" [gridOptions]="gridOptions" (gridReady)="onGridReady($event)">
        </ag-grid-angular>
    </div>
    <div *ngIf="selectedArea === 'Memorization'" class="subject-container">
        <div *ngFor="let subject of subjects" [ngClass]="{'selected': subject.selected === true}" (click)="changeSubject(subject)" class="subject">{{subject.keyValue.value}}</div>
    </div>
    <div *ngIf="selectedArea === 'Users'">
        <div style="cursor: default; width: 100%; height: 500px;display:flex; flex-direction: column; background-color: white;">
            <div style="background-color: #f8f8f8; display: flex; height:48px; justify-content: center; align-items: center; color: #181d1f; font-weight: 700;font-size:13px;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;">
                <div style="display: flex; min-width: 50px;max-width: 50px;"></div>
                <div style="min-width: 200px;max-width: 200px; padding: 0px 9px;">First Name</div>
                <div style="min-width: 200px;max-width: 200px; padding: 0px 9px;">Last Name</div>
                <div style="min-width: 150px;max-width: 150px; padding: 0px 9px;">Role</div>
                <div style="flex:1; padding: 0px 9px;">Email</div>
            </div>
            <div style="display: flex; flex: 1; flex-direction: column; overflow: auto; border-top: 1px solid #babfc7;font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;">
                <ng-container *ngFor="let user of users">
                    <div class="userRow" [ngClass]="{'selected': selectedUser === user}" (click)="selectUser(user)">
                        <div style="display: flex; min-width: 50px;max-width: 50px;">
                            <div style="width: 16px; height: 16px; margin:0px 6px;">
                                <svg style="width: 16px; height:16px; cursor: pointer;" (click)="editUser(user)" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" class="svg-inline--fa fa-edit fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"></path></svg>
                            </div>
                            <div style="width: 16px; height: 16px;">
                                <svg style="width: 16px; height:16px; cursor: pointer;" (click)="deleteUser(user)" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash-alt" class="svg-inline--fa fa-trash-alt fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"></path></svg>
                            </div>
                        </div>
                        <div style="min-width: 200px;max-width: 200px; padding: 0px 9px;">{{user.firstName}}</div>
                        <div style="min-width: 200px;max-width: 200px; padding: 0px 9px;">{{user.lastName}}</div>
                        <div style="min-width: 150px;max-width: 150px; padding: 0px 9px;">{{user.role}}</div>
                        <div style="flex:1; padding: 0px 9px;">{{user.email}}</div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div style="width: 225px; border: 1px solid black;box-shadow: rgba(121, 98, 98, 0.3) 7px 7px 5px; background-color: #fff; position: absolute; flex-wrap: wrap;" [style.display]="currentWeekOnlySelectorDisplay" [style.top.px]="currentWeekOnlySelectorTop"
        [style.left.px]="currentWeekOnlySelectorLeft">
        <div style="font-family: 'Montserrat', sans-serif;width:100%; display: flex; justify-content: space-between; align-items: center; padding: 3px 12px; border-bottom: 1px solid #000;">
            <div style="cursor: help;" title="Choose a color to group questions together. This will allow the last question in each group to appear in the cumulative view. Example: Ephesians 2:4, Ephesians 2:4-5, and Ephesians 2:4-6 would all be grouped together using the same color.">Help</div>
            <div style="height: 20px;" (click)="closeCurrentWeekOnlySelector()"><svg style="cursor: pointer; width: 20px;" aria-hidden="true" focusable="false" data-prefix="far" data-icon="times-circle" class="svg-inline--fa fa-times-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"/></svg></div>
        </div>
        <div (click)="removeCurrentWeekOnlyIndicator()" class="currentWeekCircle"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="ban" class="svg-inline--fa fa-ban fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"></path></svg></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#ff0000')" class="currentWeekCircle" style="background-color: #ff0000;"></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#ffb3b3')" class="currentWeekCircle" style="background-color: #ffb3b3;"></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#9900cc')" class="currentWeekCircle" style="background-color: #9900cc;"></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#cc33ff')" class="currentWeekCircle" style="background-color: #cc33ff;"></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#0000ff')" class="currentWeekCircle" style="background-color: #0000ff;"></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#b3b3ff')" class="currentWeekCircle" style="background-color: #b3b3ff;"></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#00cc00')" class="currentWeekCircle" style="background-color: #00cc00;"></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#ccffcc')" class="currentWeekCircle" style="background-color: #ccffcc;"></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#ffff00')" class="currentWeekCircle" style="background-color: #ffff00;"></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#ff9900')" class="currentWeekCircle" style="background-color: #ff9900;"></div>
        <div (click)="setCurrentWeekOnlyIdentifier('#ffe0b3')" class="currentWeekCircle" style="background-color: #ffe0b3;"></div>
    </div>
</div>