import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Attachment } from '../_models/attachment';
import { Memorization } from '../_models/memorization';
import { User } from '../_models/user';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    private addNewRowSource = new Subject<number>();
    private deleteRowSource = new Subject<number>();
    private dirtyChangedSource = new Subject<boolean>();
    private currentWeekOnlySource = new Subject<void>();
    private currentWeekOnlyChangeRequestedSource = new Subject<{
        top: number;
        left: number;
        data: Memorization;
    }>();
    private forceSaveMessageSource = new Subject<void>();
    private attachmentAddedSource = new Subject<void>();
    private editUserSource = new BehaviorSubject<User>(null);
    private closeEditUserSource = new Subject<void>();
    private userSavedSource = new Subject<User>();
    private userDeletedSource = new Subject<User>();

    public onAddNewRow = this.addNewRowSource.asObservable();
    public onDeleteRow = this.deleteRowSource.asObservable();
    public onDirtyChanged = this.dirtyChangedSource.asObservable();
    public onCurrentWeekOnlyChanged = this.currentWeekOnlySource.asObservable();
    public onCurrentWeekOnlyChangeRequested =
        this.currentWeekOnlyChangeRequestedSource.asObservable();
    public onForceSaveMessage = this.forceSaveMessageSource.asObservable();
    public onAttachmentAdded = this.attachmentAddedSource.asObservable();
    public onEditUserRequested = this.editUserSource.asObservable();
    public onCloseEditUserRequested = this.closeEditUserSource.asObservable();
    public onUserSaved = this.userSavedSource.asObservable();
    public onUserDeleted = this.userDeletedSource.asObservable();

    public addNewRow(index: number): void {
        this.addNewRowSource.next(index);
    }

    public deleteRow(index: number): void {
        this.deleteRowSource.next(index);
    }

    public dirtyChanged(b: boolean): void {
        this.dirtyChangedSource.next(b);
    }

    public currentWeekOnlyChanged(): void {
        this.currentWeekOnlySource.next();
    }

    public currentWeekOnlyChangeRequested(
        top: number,
        left: number,
        data: Memorization
    ) {
        this.currentWeekOnlyChangeRequestedSource.next({
            top: top,
            left: left,
            data: data,
        });
    }

    public forceSaveMessage(): void {
        this.forceSaveMessageSource.next();
    }

    public attachmentAdded(): void {
        this.attachmentAddedSource.next();
    }

    public editUser(u: User): void {
        this.editUserSource.next(u);
    }

    public closeEditUser(): void {
        this.closeEditUserSource.next();
    }

    public userSaved(u: User): void {
        this.userSavedSource.next(u);
    }

    public userDeleted(u: User): void {
        this.userDeletedSource.next(u);
    }
}
