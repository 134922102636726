export class Attachment {
    unique_id: string;
    memorization_id: string;
    attachmentBytes: string;
    name: string;
    filePath: string;
    unique_name: string;

    static create(data): Attachment {
        return Object.assign(new Attachment(), data);
    }
}
