import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
    ICellRendererParams,
    IAfterGuiAttachedParams,
} from 'ag-grid-community';
import { Attachment } from 'src/app/_models/attachment';
import { ResponseData } from 'src/app/_models/response-data';
import { AttachmentService } from 'src/app/_services/attachment.service';
import { UiService } from 'src/app/_services/ui.service';
import { AdminService } from '../admin-service';

@Component({
    templateUrl: './image-cell-renderer.component.html',
    styleUrls: ['./image-cell-renderer.component.css'],
})
export class ImageCellRendererComponent implements ICellRendererAngularComp {
    public params: any;
    public fileName: string;
    public isUploading = false;
    public filePath: string;
    public percentUploaded = 0;

    constructor(
        private adminSvc: AdminService,
        private attachmentSvc: AttachmentService,
        private uiSvc: UiService
    ) {
        this.attachmentSvc.onUploadAttachmentProgress.subscribe(
            (percentage: number) => {
                if (this.isUploading === true) {
                    this.percentUploaded = percentage;
                }
            }
        );
    }

    refresh(params: ICellRendererParams): boolean {
        return params.value;
    }
    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

    agInit(params) {
        this.params = params;
        if (this.params.data && this.params.data.image) {
            this.fileName = this.params.data.image.name;
            this.filePath = this.params.data.image.filePath;
        }
    }
    public imageSelected(e): void {
        this.isUploading = true;
        this.attachmentSvc
            .uploadAttachment(e.target.files[0])
            .then((filePath: string) => {
                setTimeout(() => {
                    this.fileName = e.target.files[0].name;
                    this.filePath = filePath;
                    this.params.node.data.image = new Attachment();
                    this.params.node.data.image.name = this.fileName;
                    this.params.node.data.image.filePath = filePath;

                    this.adminSvc.attachmentAdded();
                    this.isUploading = false;
                }, 500);
            })
            .catch((reason) => {
                this.isUploading = false;
                this.uiSvc.showAlert(reason);
            });
    }

    preview(): void {
        this.attachmentSvc.previewImage(this.filePath);
    }

    delete(): void {
        this.fileName = null;
        this.params.node.data.image = null;
        this.adminSvc.attachmentAdded();
    }
}
