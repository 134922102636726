import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UiService {
    private showAlertSource = new Subject<string>();
    public onShowAlert = this.showAlertSource.asObservable();

    constructor() {}

    public showAlert(text: string): void {
        this.showAlertSource.next(text);
    }
}
