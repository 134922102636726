import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import {
    ICellRendererParams,
    IAfterGuiAttachedParams,
} from 'ag-grid-community';
import { AdminService } from '../admin-service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'checkbox-renderer',
    templateUrl: './checkbox-cell-renderer.component.html',
    styleUrls: ['./checkbox-cell-renderer.component.css'],
})
export class CheckboxRendererComponent implements ICellRendererAngularComp {
    private params: any;
    public shouldShow = true;

    constructor(private adminSvc: AdminService) {
        this.adminSvc.onDirtyChanged.subscribe((b) => {
            this.shouldShow = this.params.data.question ? true : false;
        });
    }

    refresh(params: ICellRendererParams): boolean {
        return params.value;
    }
    afterGuiAttached?(params?: IAfterGuiAttachedParams): void {}

    agInit(params: any): void {
        this.params = params;
        this.shouldShow = this.params.data.question ? true : false;
    }

    checkedHandler(event) {
        const checked = event.target.checked;
        const colId = this.params.column.colId;
        this.params.node.setDataValue(colId, checked);
    }
}
