import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
    public errorMessage = '';
    public isLoggedOut = false;
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private zone: NgZone
    ) {}

    async ngOnInit() {
        this.isLoggedOut = this.authenticationService.isLoggedOut;
        const loggegIn = await this.authenticationService.validateToken();
        if (loggegIn === true) {
            this.router.navigate(['/']);
        } else {
            window['google'].accounts.id.initialize({
                client_id:
                    '156864666912-q3l5bvub23gin2ruvdap4ornuo839729.apps.googleusercontent.com',
                callback: this.handleCredentialResponse.bind(this),
                auto_select: false,
                cancel_on_tap_outside: true,
            });
            window['google'].accounts.id.renderButton(
                document.getElementById('google-button'),
                { theme: 'outline', size: 'large', width: '100%' }
            );
        }
    }

    private handleCredentialResponse(response: any) {
        this.zone.run(() => {
            this.errorMessage = undefined;
            this.authenticationService
                .authenticate(response.credential)
                .then(() => {
                    this.router.navigate(['/']);
                })
                .catch((reason: string) => {
                    this.errorMessage = reason;
                });
        });
    }
}
